import Index1 from "./pages/Index1/Index1";
import Privacy from "./pages/Index1/Privacy";
import Cookie from "./pages/Index1/Cookie";
import Contact from "./pages/Index1/Contact";
import Terms from "./pages/Index1/Terms";

import GetStarted from "./pages/Index1/GetStarted";
import GetStartedRecruiters from "./pages/Index1/GetStartedRecruiters";

const routes = [
  { path: "/", component: <Index1 /> },
  { path: "/privacy", component: <Privacy /> },
  { path: "/cookie-policy", component: <Cookie /> },
  { path: "/contact-us", component: <Contact /> },
  { path: "/terms-and-conditions", component: <Terms /> },

  { path: "/signup", component: <GetStarted /> },
  { path: "/recruiters/signup", component: <GetStartedRecruiters /> },
  { path: "/home", component: <Index1 /> },
];

export default routes;
