import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import homeImg from "../../assets/images/home-img.png";

class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="hero-section" id="home">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="hero-wrapper mb-4">
                  <p className="font-16 text-uppercase">
                    Transform Your Job Search
                  </p>
                  <h1 className="hero-title mb-4">
                    Showcase Your Talent Like Never Before with{" "}
                    <span className="text-primary">Sikollo</span>
                  </h1>

                  <p>
                    Tired from daily job applications with no offers? With
                    Sikollo, recruiters come to you, recognizing your talents
                    immediately.
                  </p>

                  <div className="mt-4">
                    <Link to="/signup" className="btn btn-primary mt-2 me-2">
                      Get Started
                    </Link>
                    <Link
                      to="/recruiters/signup"
                      className="btn btn-success mt-2 me-2 ms-1"
                    >
                      Recruiters Get Started
                    </Link>
                  </div>
                </div>
              </Col>

              <Col lg={6} sm={8}>
                <div className="home-img mt-5 mt-lg-0">
                  <img
                    src={homeImg}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
