import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../../assets/images/features/img-1.png";
import feature2 from "../../assets/images/features/img-2.png";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smallFeatures1: [
        { title: "Skill Highlight" },
        { title: "Engaging Content" },
        { title: "Personal Branding" },
        { title: "Dynamic Display" },
      ],
      smallFeatures2: [
        { title: "Precise Matching" },
        { title: "Enhanced Visibility" },
        { title: "Time Saving" },
        { title: "Targeted Opportunities" },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle
              subtitle="Features"
              title="Powerful Features Designed for Success"
              desc="Unlock Sikollo's innovative features to showcase your skills and connect with top employers. "
            />

            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon="bar-chart-2"
                    title="Video Skill Showcase"
                    smallFeatures={this.state.smallFeatures1}
                    desc="Highlight your unique abilities through personalized video content on Sikollo. Stand out to recruiters by demonstrating your skills in action."
                    link="#"
                  />
                </div>
              </Col>

              <Col lg={5} sm={8} className="ms-lg-auto">
                <Card className="border border-light shadow-none mt-5 mt-lg-0">
                  <CardHeader className="border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger mr-1"></i>
                      <i className="mdi mdi-circle text-warning mr-1 ms-1"></i>
                      <i className="mdi mdi-circle text-success mr-1 ms-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={feature1}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-5 pt-5">
              <Col lg={5} sm={8}>
                <Card className="border border-light shadow-none">
                  <CardHeader className=" border-0 bg-transparent">
                    <div>
                      <i className="mdi mdi-circle text-danger me-1"></i>
                      <i className="mdi mdi-circle text-warning me-1 ms-1"></i>
                      <i className="mdi mdi-circle text-success me-1 ms-1"></i>
                    </div>
                  </CardHeader>
                  <CardBody className="bg-light">
                    <div className="box-shadow">
                      <img
                        src={feature2}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className="ms-lg-auto">
                <div className="mt-4 mt-lg-0">
                  <FeatureBox
                    icon="pie-chart"
                    title="AI-Driven Matching"
                    smallFeatures={this.state.smallFeatures2}
                    desc="If several languages coalesce, the grammar of the resulting language is more simple and regular."
                    link="#"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
