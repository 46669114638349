import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import SectionTitle from "../common/section-title";

class CookiePage extends Component {
  renderCookieDetails() {
    return (
      <div>
        <h2>Cookie Usage</h2>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our service and hold certain information.
        </p>
        <h3>Types of Cookies We Use</h3>
        <ul>
          <li>
            <strong>Essential Cookies:</strong> These cookies are essential to
            provide you with services available through our website and to
            enable you to use some of its features. Without these cookies, the
            services that you have asked for cannot be provided, and we only use
            these cookies to provide you with those services.
          </li>
          <li>
            <strong>Analytics and Performance Cookies:</strong> These cookies
            are used to collect information about traffic to our site and how
            users use our site. The information gathered does not identify any
            individual visitor. It is aggregated and anonymous. The information
            is only used to improve how our website works.
          </li>
          <li>
            <strong>Functionality Cookies:</strong> These cookies allow our
            website to remember choices you make when you use our website, such
            as remembering your login details or language preference. The
            purpose of these cookies is to provide you with a more personal
            experience and to avoid you having to re-enter your preferences
            every time you use our website.
          </li>
          <li>
            <strong>Advertising Cookies:</strong> These cookies are used to
            serve you with advertisements that may be relevant to you and your
            interests. They are also used to limit the number of times you see
            an advertisement as well as help measure the effectiveness of the
            advertising campaign.
          </li>
        </ul>
        <h3>Your Choices Regarding Cookies</h3>
        <p>
          If you prefer to avoid the use of cookies on the website, first you
          must disable the use of cookies in your browser and then delete the
          cookies saved in your browser associated with this website. You may
          use this option for preventing the use of cookies at any time.
        </p>
        <p>
          If you do not accept our cookies, you may experience some
          inconvenience in your use of the website and some features may not
          function properly.
        </p>
        <p>
          If you'd like to delete cookies or instruct your web browser to delete
          or refuse cookies, please visit the help pages of your web browser.
        </p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="cookie-policy">
          <Container>
            <SectionTitle title="Cookie Policy" />
            <Row>
              <Col lg={6} className="offset-lg-3">
                {this.renderCookieDetails()}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default CookiePage;
