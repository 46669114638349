import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";

class PrivacyPage extends Component {
  renderSuccessMessage() {
    return (
      <div className="text-center">
        <p>
          Welcome to Sikollo! We respect your privacy and are committed to
          protecting your personal data. This privacy policy will inform you as
          to how we look after your personal data when you visit our website
          (regardless of where you visit it from) and tell you about your
          privacy rights and how the law protects you.
        </p>
        <h2>1. Information We Collect</h2>
        <p>We collect the following types of information:</p>
        <ul style={{ textAlign: "left" }}>
          <li>
            <strong>Personal Identification Information</strong>: Name, email
            address, phone number, etc.
          </li>
          <li>
            <strong>Technical Data</strong>: IP address, browser type and
            version, time zone setting, browser plug-in types, operating system
            and platform, and other technology on the devices you use to access
            this website.
          </li>
        </ul>
        <h2>2. How We Use Your Data</h2>
        <p>We use your data to:</p>
        <ul style={{ textAlign: "left" }}>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
        <h2>3. How We Store and Secure Your Data</h2>
        <p>
          Sikollo uses the data hosting service providers in the United States
          to host the data we collect, and we use technical measures to secure
          your data.
        </p>
        <h2>4. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies (like web beacons and
          pixels) to access or store information. Specific information about how
          we use such technologies and how you can refuse certain cookies is set
          out in our Cookie Policy.
        </p>
        <h2>5. Disclosure of Your Personal Data</h2>
        <p>
          We may disclose your personal data to our subsidiaries, affiliated
          companies, and to third-party service providers for the purpose of
          providing or improving our services, subject to the law.
        </p>
        <h2>6. Legal Basis for Processing Personal Data</h2>
        <p>
          Our legal basis for collecting and using the personal data described
          in this privacy policy depends on the personal data we collect and the
          specific context in which we collect it.
        </p>
        <h2>7. Your Data Protection Rights</h2>
        <p>You have the following data protection rights:</p>
        <ul style={{ textAlign: "left" }}>
          <li>
            The right to access, update, or delete the information we have on
            you.
          </li>
          <li>The right of rectification.</li>
          <li>The right to object.</li>
          <li>The right of restriction.</li>
          <li>The right to data portability</li>
          <li>The right to withdraw consent</li>
        </ul>
        <h2>8. Third-party Links</h2>
        <p>
          This website may include links to third-party websites, plug-ins, and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements.
        </p>
        <h2>9. Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="get-started">
          <Container>
            <SectionTitle title="Privacy Policy" />
            <Row>
              <Col lg={6} className="offset-lg-3">
                {this.renderSuccessMessage()}{" "}
                {/* Corrected the function call */}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default PrivacyPage;
