import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import FeatherIcon from "feather-icons-react";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  state = {
    links1: [
      { link: "#", title: "About Us" },
      { link: "#", title: "Media & Press" },
      { link: "#", title: "Career" },
      { link: "#", title: "Blog" },
    ],
    links2: [
      { link: "/privacy", title: "Privacy Policy" },
      { link: "/cookie-policy", title: "Cookie Policy" },
      //   { link: "#", title: "Refund Policy" },
      { link: "/contact-us", title: "Contact us" },
      { link: "/terms-and-conditions", title: "Terms and Conditions" },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col lg={4} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">About the Sikollo</h5>
                  <p>
                    Sikollo revolutionizes job searching with video technology
                    and AI-driven matching, connecting talent with global
                    opportunities efficiently. Simplify your recruitment or job
                    search and achieve professional growth with Sikollo.
                  </p>
                </div>
              </Col>
              <Col lg={{ size: 2, offset: 1 }} sm={6}>
                {/* <div>
                  <h5 className="mb-4 footer-list-title">Company</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.links1.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </Col>
              <Col lg={2} sm={6}>
                {/* <div>
                  <h5 className="mb-4 footer-list-title">More Info</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.links2.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </Col>

              <Col lg={3} sm={6}>
                <div>
                  <h5 className="mb-4 footer-list-title">More Info</h5>
                  <ul className="list-unstyled footer-list-menu">
                    {this.state.links2.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
