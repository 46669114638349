// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import * as serviceWorker from "./serviceWorker";
// import { BrowserRouter } from "react-router-dom";
// const app = (
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );
// ReactDOM.render(app, document.getElementById("root"));
// serviceWorker.unregister();

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import { Provider } from "react-redux";
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './slices';

const root = ReactDOM.createRoot(document.getElementById("root"));

// Add the Google Analytics script
const addGoogleAnalytics = () => {
  const script1 = document.createElement("script");
  script1.async = true;
  script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-374562111";
  document.head.appendChild(script1);

  const script2 = document.createElement("script");
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-374562111');
  `;
  document.head.appendChild(script2);
};

addGoogleAnalytics();

// const store = configureStore({ reducer: rootReducer, devTools: true });
root.render(
  <React.Fragment>
    {/* <Provider store={store}> */}
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
    {/* </Provider> */}
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
