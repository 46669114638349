import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../../components/Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.png";
import clinet6 from "../../assets/images/clients/6.png";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [
        {
          name: "Henry McElyea",
          post: "Digital Marketing Manager",
          desc: "The transformation in our recruitment process has been remarkable with Sikollo. Seeing candidates showcase their skills in live sessions saves us countless hours.",
        },
        {
          name: "Timothy Fairley",
          post: "Senior Software Developer",
          desc: "Landing a great job within weeks was a breeze, thanks to the AI-driven matching at Sikollo. My profile was immediately noticed by the right employers.",
        },
        {
          name: "James Brown",
          post: "HR Coordinator",
          desc: "The live interview recordings from Sikollo provide a real sense of each candidate's personality and capabilities, revolutionizing our hiring approach.",
        },
        {
          name: "Jason Davis",
          post: "Project Manager",
          desc: "Presenting my project management skills through Sikollo's video skill showcase made my job search highly effective and targeted.",
        },
        {
          name: "Rodney Tyler",
          post: "Creative Director",
          desc: "For creative roles, Sikollo is invaluable. It's incredibly valuable to see actual work samples from candidates right at the outset.",
        },
      ],
      clients2: [
        { image: clinet1 },
        { image: clinet3 },
        { image: clinet4 },
        { image: clinet6 },
      ],
      responsive: {
        576: {
          items: 2,
        },
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="clients">
          <Container>
            <SectionTitle
              subtitle="Testimonial"
              title="What our Members Say"
              desc=""
            />

            <Row>
              <Col lg={12}>
                <h5 className="mb-4">
                  <i className="mdi mdi-format-quote-open text-primary h1 mr-1"></i>{" "}
                  1,500 + Satisfied Member
                </h5>

                <OwlCarousel
                  className="owl-theme testi-carousel"
                  id="testi-carousel"
                  items={1}
                  loop={true}
                  margin={10}
                  nav={true}
                  responsive={this.state.responsive}
                >
                  <ClientBox clients={this.state.clients} />
                </OwlCarousel>
              </Col>
            </Row>

            {/* <Row className="mt-5">
              {this.state.clients2.map((client, key) => (
                <Col xl={3} sm={6} key={key}>
                  <div className="client-images">
                    <img
                      src={client.image}
                      alt="client-img"
                      className="mx-auto img-fluid d-block"
                    />
                  </div>
                </Col>
              ))}
            </Row> */}
          </Container>
        </section>

        <section className="section bg-primary">
          <Container>
            <Row className="justify-content-center mb-5">
              <Col lg={7}>
                <div className="text-center text-white-50">
                  <h4 className="text-white">
                    Get Started with Sikollo Today!
                  </h4>
                  <p>
                    Jumpstart your career with Sikollo, where opportunities come
                    to you! Sign up now to create your professional profile,
                    conduct interviews on Sikollo, and let our AI start matching
                    you with your dream job. Don't wait to make your next big
                    career move—join Sikollo and connect with top employers
                    around the globe.
                  </p>
                </div>
              </Col>
            </Row>
            {/* 
            <Counter /> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
