import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          icon: "video",
          title: "Live Interview Recording",
          desc: "Record and share your interviews in real-time, showcasing your skills to recruiters.",
        },
        {
          icon: "camera",
          title: "Video Skill Showcase",
          desc: "Upload videos that demonstrate your expertise, making you stand out.",
        },
        {
          icon: "cpu",
          title: "AI-Driven Matching",
          desc: "Our AI matches your skills with ideal job listings to ensure you never miss an opportunity.",
        },
        {
          icon: "eye",
          title: "Profile Visibility",
          desc: "Boost your profile’s visibility to top recruiters, directly showcasing your skills.",
        },
        {
          icon: "mail",
          title: "Interview Invitations",
          desc: "Invite employers to interview you on Sikollo, making your interview accessible to any recruiters.",
        },
        {
          icon: "message-circle",
          title: "Real-Time Feedback",
          desc: "Receive instant feedback post-interview to refine and enhance your performance.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            <SectionTitle
              subtitle="Services"
              title="Services We Provide"
              desc="Everything You Need for a Smoother, Faster Job Search."
            />

            <Row>
              <ServiceBox services={this.state.services} />
            </Row>
            {/* 
            <Row className="mt-4">
              <Col lg={12}>
                <div className="text-center">
                  <Link to="#" className="btn btn-success">
                    View more
                  </Link>
                </div>
              </Col>
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
