import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import logolight from "../../assets/images/logo-light.png";

class FooterLinks extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-primary py-3">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="float-sm-start">
                  <Link to="#">
                    <img src={logolight} alt="" height="30" />
                  </Link>
                </div>
                <div className="float-sm-end mt-4 mt-sm-0">
                  <p className="copyright-desc text-white mb-0">
                    {new Date().getFullYear()} © Sikollo
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
