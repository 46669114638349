import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { firestore } from "../../firebase";
import SectionTitle from "../common/section-title";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

class GetStartedPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      formSubmitted: false,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { firstName, lastName, jobTitle, email } = this.state;

    try {
      const docRef = await addDoc(collection(firestore, "candidates"), {
        firstName,
        lastName,
        jobTitle,
        email,
        timestamp: serverTimestamp(),
      });
      this.setState({ formSubmitted: true });
    } catch (error) {
      //console.error("Error writing document: ", error);
      alert("Failed to submit the form, please try again.");
    }
  };

  renderForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="firstName">First Name</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            placeholder="Enter your first name"
            value={this.state.firstName}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name</Label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Enter your last name"
            value={this.state.lastName}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="jobTitle">Job Title</Label>
          <Input
            type="text"
            name="jobTitle"
            id="jobTitle"
            placeholder="Enter your job title"
            value={this.state.jobTitle}
            onChange={this.handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <Button type="submit" color="primary">
          Submit
        </Button>
      </Form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="text-center">
        <h2>Thank You for Your Interest!</h2>
        <p>
          We're currently in the early stages of developing a new platform that
          revolutionizes how individuals like you connect with job
          opportunities. Your interest and feedback are crucial for us to tailor
          our services to best meet your needs.
        </p>
        <p>
          We will keep you updated with our progress and let you know as soon as
          we have more to share!
        </p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="get-started">
          <Container>
            <SectionTitle title="Get Started with Us" />
            <Row>
              <Col lg={6} className="offset-lg-3">
                {this.state.formSubmitted
                  ? this.renderSuccessMessage()
                  : this.renderForm()}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GetStartedPage;
