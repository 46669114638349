import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";

class TermsAndServicesPage extends Component {
  renderTermsAndServices() {
    return (
      <div className="text-center">
        <p>
          Thank you for choosing Sikollo. Please read our Terms of Service
          carefully as they govern your use of our website and services.
        </p>
        <h2>1. Agreement to Terms</h2>
        <p>
          By accessing our website, you agree to be bound by these Terms of
          Service and our Privacy Policy.
        </p>
        <h2>2. Changes to Terms</h2>
        <p>
          We reserve the right to modify these terms at any time. Your continued
          use of our services after any changes constitutes your acceptance of
          the new terms.
        </p>
        <h2>3. Account Responsibilities</h2>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your computer.
        </p>
        <h2>4. Prohibited Activities</h2>
        <p>
          You are prohibited from using the site or its content for any illegal
          or unauthorized purpose.
        </p>
        <h2>5. Intellectual Property Rights</h2>
        <p>
          The content on our website is owned by or licensed to Sikollo. This
          content includes, but is not limited to, the design, layout, look,
          appearance, and graphics.
        </p>
        <h2>6. Termination</h2>
        <p>
          We may terminate or suspend access to our service immediately, without
          prior notice or liability, for any reason whatsoever, including,
          without limitation, if you breach the Terms.
        </p>
        <h2>7. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of the jurisdiction in which our company is established.
        </p>
        <h2>8. Contact Information</h2>
        <p>If you have any questions about these Terms, please contact us.</p>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="get-started">
          <Container>
            <SectionTitle title="Terms of Service" />
            <Row>
              <Col lg={6} className="offset-lg-3">
                {this.renderTermsAndServices()}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default TermsAndServicesPage;
