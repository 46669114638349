import React, { Component } from "react";
import routes from "./routes";
import { Routes, Route } from "react-router-dom";

// Import Scss
import "./theme.scss";

// Import Icon Css
import "./assets/css/materialdesignicons.min.css";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Routes>
          {routes.map((route, idx) => (
            <Route key={idx} path={route.path} element={route.component} />
          ))}
        </Routes>
      </React.Fragment>
    );
  }
}

export default App;
