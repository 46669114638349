import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

// Import Section Title
import SectionTitle from "../common/section-title";
import { firestore } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      messageSent: false, // Flag to check if the message has been sent
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // Destructure state variables
    const { name, email, message } = this.state;

    try {
      // Add a new document with a generated ID to the "email" collection
      await addDoc(collection(firestore, "emails"), {
        name,
        email,
        message,
        timestamp: serverTimestamp(),
      });
      // Clear form fields and set messageSent to true to display success message
      this.setState({
        name: "",
        email: "",
        message: "",
        messageSent: true,
      });
    } catch (error) {
      //console.error("Error adding document to Firestore: ", error);
      alert("Failed to submit the form, please try again.");
    }
  };

  renderContactForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Enter your name"
            value={this.state.name}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            placeholder="Your message"
            value={this.state.message}
            onChange={this.handleInputChange}
            required
          />
        </FormGroup>
        <Button type="submit" color="primary">
          Send Message
        </Button>
      </Form>
    );
  }

  renderSuccessMessage() {
    return (
      <div className="text-center">
        <h2>Thank You for Contacting Us!</h2>
        <p>
          We have received your message and will get back to you as soon as
          possible.
        </p>
        <Button
          color="primary"
          onClick={() => this.setState({ messageSent: false })}
        >
          Send Another Message
        </Button>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="contact-us">
          <Container>
            <SectionTitle title="Contact Us" />
            <Row>
              <Col lg={6} className="offset-lg-3">
                {this.state.messageSent
                  ? this.renderSuccessMessage()
                  : this.renderContactForm()}
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactPage;
